<template>
    <div class="col-12 g">
        <div class="card card-body">
            <img :src="require('@/assets/images/loading.svg')" alt="" width="50" v-if="loading">
            <div class="col-12 table-responsive">
                <table class="table table-hover table-sm table-striped table-bordered" id="headerTable">
                    <thead>
                        <th>
                            المستخدم
                        </th>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            الموضوع
                        </th>
                        <th>
                            الخدمة
                        </th>
                        <th  v-if="type =='feedback'">
                            التقييم
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            خيارات
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="user in users" :key="user._id" :class="user.unread > 0 ? 'bg-light-warning' : ''">
                            <td>
                                <span v-if="user.user">
                                    <span v-if="user.user._id">
                                        <a target="_blank" :href="'https://s.tahdir.net/autologin/' + user.user.jwt">
                                        <i class="fa fa-external-link"></i>
                                        {{user.user.name}} </a>
                                    </span>
                                </span>
                            </td>
                            <td>
                                {{ user.date }}
                            </td>
                            <td>
                                {{ user.title.substring(0,30) }}...
                            </td>
                            <td>
                                {{ user.service }}
                            </td>
                            <td v-if="type =='feedback'">
                                {{ user.rate }}
                            </td>
                            <td>
                                <span v-if="user.status == 0" class="text-danger">مفتوحة {{ user.unread }}</span>
                                <span v-if="user.status == 1">مغلقة</span>
                            </td>
                            <td>
                                <button class="bg-success text-white" @click="$router.push('/ticket/' + user._id)">
                                    فتح
                                    <i class="fa fa-arrow-left"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BDropdown,
        BDropdownItem,
        BModal,
    },
    directives: {
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            loading: true,
            loading2: true,
            jwt: localStorage.getItem('jwt'),
            users: [],
            q: "",
            page: 0,
            services: [],
            selectedUser: {},
            type: window.location.href?.split('type/')[1]?.split("?")[0],
            int: null
        }
    },
    beforeDestroy(){
        clearInterval(this.int)
    },
    created(){
        var g = this;
        g.gett();
        g.int = setInterval(() => {
            if(!window.location.href.includes("done")){
                window.location = window.location.href + '?done=r'
            }
        }, 1000);
    },
    methods: {
        gett(){
            var g = this;
            g.loading = true;
            $.post(api + '/admin/feedbacks', {
                jwt: this.jwt,
                type: window.location.href.split('type/')[1].split("?")[0],
                q: this.q,
                page: this.page,
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.users = r.response
                }
            }).fail(function(){
                g.loading = false;
            })
        },
    }
}
</script>
<style scoped>
</style>